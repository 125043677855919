import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import {
    Alert,
    Box,
    Card,
    CardContent,
    Chip,
    FormControl,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import React from 'react'
import { Legend } from '../../sites/site.page'
import { CardTitle } from './card-title.component'
import { EnedisDisabled } from './enedis-disabled.component'
import { LineChartComponent } from './line.component'
import { WarningText } from './warning.text'
import { useSelector } from 'react-redux'
import moment from 'moment'

let _default = [
    {
        name: 'Puissance moy.',
        data: [
            {
                y: 17864,
                x: 1670803200000,
            },
            {
                y: 18926,
                x: 1670889600000,
            },
            {
                y: 14331,
                x: 1670976000000,
            },
            {
                y: 14026,
                x: 1671062400000,
            },
            {
                y: 15440,
                x: 1671148800000,
            },
            {
                y: 14639,
                x: 1671235200000,
            },
            {
                y: 15397,
                x: 1671321600000,
            },
        ],
        hash: 4,
        visible: true,
        marker: {
            enabled: false,
        },
        type: 'column',
        spacingLeft: 10,
        yAxis: 0,
        color: {
            linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
            },
            stops: [
                [0, '#52d187'],
                [1, 'rgba(82, 209, 135, 0.5)'],
            ],
        },
    },
    {
        name: 'Puissance max.',
        data: [
            {
                y: 26019,
                x: 1670803200000,
            },
            {
                y: 37000,
                x: 1670889600000,
            },
            {
                y: 25000,
                x: 1670976000000,
            },
            {
                y: 25000,
                x: 1671062400000,
            },
            {
                y: 24000,
                x: 1671148800000,
            },
            {
                y: 24020,
                x: 1671235200000,
            },
            {
                y: 26000,
                x: 1671321600000,
            },
        ],
        hash: 4,
        visible: true,
        marker: {
            enabled: false,
        },
        type: 'line',
        spacingLeft: 10,
        yAxis: 0,
        color: 'red',
        dataLabels: {
            enabled: true,
        },
    },
]

export const BuildingChart = ({
    series,
    events,
    energyDisplay,
    setEnergyDisplay,
    meters,
    selectedMeter,
    setSelectedMeter,
    totalDuration,
    floatRight,
    xAxis,
    title,
    selectedSites,
    setSelectedSites,
    hideChips,
    hideOpposite,
    origin,
}) => {
    const ref = React.useRef(series)
    const { selectedSite } = useSelector((state) => state.globalStore)
    const calendarStore = useSelector((state) => state.calendarStore)

    return (
        <Card ref={ref}>
            <CardContent style={{ position: 'relative' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: { md: 'block', xs: 'none' }, flexWrap: 'wrap' }}>
                        <CardTitle title={title || 'Données Enedis & Températures'} />
                        {!hideChips &&
                            selectedSites?.map((el) => {
                                return (
                                    <Chip
                                        key={el?.hash}
                                        label={el?.name}
                                        style={{
                                            cursor: 'pointer',
                                            margin: 2,
                                            fontSize: 12,
                                            backgroundColor: el?.color,
                                            color: el?.color ? 'white' : '',
                                        }}
                                        deleteIcon={
                                            el?.enabled ? (
                                                <DoneIcon style={{ fontSize: 12 }} />
                                            ) : (
                                                <CloseIcon style={{ fontSize: 12 }} />
                                            )
                                        }
                                        onDelete={() => {}}
                                        onClick={() => {
                                            let _selectedSites = selectedSites?.map((site) => {
                                                if (site?.hash == el?.hash) return { ...site, enabled: !site?.enabled }
                                                return site
                                            })
                                            if (_selectedSites?.filter((el) => el.enabled)?.length > 0)
                                                setSelectedSites(_selectedSites)
                                        }}
                                        {...(el?.enabled && !el?.color ? { color: 'primary' } : null)}
                                    />
                                )
                            })}
                    </Box>

                    <div style={{ display: 'flex' }}>
                        {meters?.length > 0 && (
                            <FormControl style={{ marginRight: 10 }}>
                                <Select
                                    id="selected-meter"
                                    value={selectedMeter?.Prm || ''}
                                    onChange={(e) => {
                                        let find = meters?.find((el) => el?.Prm == e.target.value)
                                        setSelectedMeter(find)
                                    }}
                                    disabled={meters?.length === 1}
                                >
                                    {meters?.map((el) => {
                                        return (
                                            <MenuItem value={el?.Prm} key={el?.Prm}>
                                                {el?.Prm} - {el?.Pmax + el?.PmaxUnit}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        )}
                        {meters?.length > 0 ? (
                            <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                                {setEnergyDisplay ? (
                                    <FormControl>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={energyDisplay}
                                            onChange={(e) => setEnergyDisplay(e.target.value)}
                                        >
                                            <MenuItem value={'power'}>Puissance</MenuItem>
                                            <MenuItem value={'consumption'}>Consommation</MenuItem>
                                        </Select>
                                    </FormControl>
                                ) : null}
                            </Box>
                        ) : origin !== 'group' ? (
                            <WarningText text="pas de compteur Enedis associé" />
                        ) : null}

                        {floatRight}
                    </div>
                </div>
                {meters?.length > 0 ? (
                    <Box
                        sx={{
                            display: { md: 'none', sm: 'none', xs: 'block' },
                            marginTop: {
                                md: 0,
                                xs: 2,
                            },
                        }}
                    >
                        {setEnergyDisplay ? (
                            <FormControl>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={energyDisplay}
                                    onChange={(e) => setEnergyDisplay(e.target.value)}
                                >
                                    <MenuItem value={'power'}>Puissance</MenuItem>
                                    <MenuItem value={'consumption'}>Consommation</MenuItem>
                                </Select>
                            </FormControl>
                        ) : null}
                    </Box>
                ) : origin !== 'group' ? (
                    <WarningText text="pas de compteur Enedis associé" />
                ) : null}
                <br />
                {series !== null && series !== undefined ? (
                    <LineChartComponent
                        toggle={false}
                        series={series?.length > 0 ? series : _default}
                        exporting={false}
                        xAxis={
                            xAxis
                                ? xAxis
                                : {
                                      type: 'datetime',
                                      labels: {
                                          format: '{value:%d-%m-%Y}',
                                          align: 'center',
                                      },
                                  }
                        }
                        yAxis={[
                            {
                                title: {
                                    text:
                                        energyDisplay === 'power'
                                            ? 'Puissance moyenne et max. (W)'
                                            : energyDisplay === 'consumption'
                                              ? 'Consommation (kWh)'
                                              : '',
                                },
                                min: 0,
                                plotLines:
                                    energyDisplay === 'power'
                                        ? [
                                              {
                                                  color: 'black',
                                                  dashStyle: 'dot',
                                                  width: 2,
                                                  value: parseInt(selectedMeter?.Pmax) * 1000,
                                                  label: {
                                                      align: 'right',
                                                      style: {
                                                          fontStyle: 'italic',
                                                      },
                                                      text: 'Puissance souscrite max.',
                                                      x: -10,
                                                  },
                                                  zIndex: 3,
                                              },
                                          ]
                                        : [],
                            },
                            {
                                title: {
                                    text: '°C',
                                },
                                opposite: true,
                            },
                        ]?.filter((el) => {
                            if (hideOpposite) return el?.opposite !== true
                            else return true
                        })}
                        plotOptions={{
                            column: {
                                stacking: 'normal',
                                maxPointWidth: 40,
                                borderRadius: 2,
                            },
                            series: {
                                animation: false,
                            },
                            areaspline: {
                                fillOpacity: 0.4,
                            },
                            chart: {
                                margin: 0,
                            },
                        }}
                        type="column"
                        events={events || {}}
                    />
                ) : null}
                <Legend
                    legends={series
                        ?.filter((el) => el?.showLegend)
                        ?.map((el) => {
                            return { color: el?.color, content: el?.name }
                        })}
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                    legendStyle={{ marginRight: 10 }}
                />

                {selectedSite?.notes?.filter((note) => {
                    return (
                        moment(note?.Date).diff(calendarStore.start) >= 0 &&
                        moment(note?.Date).diff(calendarStore.end) <= 0
                    )
                })?.length > 0 && (
                    <TableContainer style={{ marginTop: 20, fontSize: 10 }}>
                        <Table
                            // sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                        >
                            <TableHead></TableHead>
                            <TableBody>
                                {selectedSite?.notes
                                    ?.filter((note) => {
                                        return (
                                            moment(note?.Date).diff(calendarStore.start) >= 0 &&
                                            moment(note?.Date).diff(calendarStore.end) <= 0
                                        )
                                    })
                                    ?.sort((a, b) => {
                                        return a?.Date < b?.Date ? -1 : 1
                                    })
                                    ?.map((note) => {
                                        return (
                                            <TableRow key={note?.MaintenanceNoteId} style={{}}>
                                                <TableCell width={150} style={{ fontSize: 12 }}>
                                                    {moment(note?.Date).format('DD-MM-YYYY')}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'left', fontSize: 12 }}>
                                                    <b>{note?.Title}</b>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {(!series || !series?.length) && <EnedisDisabled loading={series?.length !== 0} />}
                {totalDuration > 0 && (
                    <Alert severity="info">
                        Vous avez dépassé votre puissance maximale souscrite pendant {totalDuration} minutes.
                    </Alert>
                )}
            </CardContent>
        </Card>
    )
}
