import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import {
    Alert,
    Backdrop,
    Badge,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Tab,
    Tabs,
    TextField,
    Select,
    MenuItem,
    Box,
} from '@mui/material'
import hash from 'object-hash'
import SetupComponent from '../../components/Global/setup.component'

import { getObjectsForSite } from '../../services/realtime.service'
import useLocale from '../../utils/locale/locale.hook'

import { Button, Grid } from '@mui/material'
import moment from 'moment'
import 'moment/locale/fr'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { getConstraints, getGroups, postCheckin, postConstraints, postGroups } from '../../services/constraints.service'
import { store } from '../../store/configure.store'
import GlobalSlice from '../../store/slices/global.slice'
import { event } from '../../utils/ga.utils'

import { ContainerComponent } from '../../components/Global/container.component'
import { setupBuildings } from '../../services/site.service'
import SaveMenu from './components/save-menu.component'
import { Tab1Component } from './components/tab-1.component'
import { Tab2Component } from './components/tab-2.component'
import { Tab3Component } from './components/tab-3.component'
import { isDemo } from '../../services/utils.service'
import { ForceResetComponent } from '../../components/Site/force-reset.component'

const DialogComponent = ({ dialog, setDialog, title, description, noText, yesText, accept, reset }) => {
    return (
        <Dialog
            open={dialog}
            onClose={() => {
                reset()
                setDialog(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {noText && <Button onClick={() => setDialog(false)}>{noText}</Button>}
                <Button onClick={accept} autoFocus>
                    {yesText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const ConstraintsPage = () => {
    const selectedSite = useSelector((state) => state.globalStore.selectedSite)
    const selectedObject = useSelector((state) => state.globalStore.selectedObject)
    const me = useSelector((state) => state.meStore?.me)
    const globalStore = useSelector((state) => state.globalStore)
    const [modal, setModal] = useState(false)
    const locale = useLocale()
    const [constraints, setConstraints] = useState(null)
    const [loading, setLoading] = useState(true)
    const [rawObjects, setRawObjects] = useState([])
    const [dialog, setDialog] = useState(false)
    const [groupDialog, setGroupDialog] = useState(false)
    const [objectUpdates, setObjectUpdates] = useState([])
    const navigate = useNavigate()
    const [groups, setGroups] = useState([])
    const [checkIn, setCheckIn] = useState('14:00:00')
    const [checkOut, setCheckOut] = useState('11:00:00')

    const [weekendCheckIn, setWeekendCheckIn] = useState(null)
    const [weekendCheckOut, setWeekendCheckOut] = useState(null)
    const [defaultCheckin, setDefaultCheckin] = useState(null)
    const [defaultCheckout, setDefaultCheckout] = useState(null)
    const [constraintsUpdated, setConstraintsUpdated] = useState(false)
    const [initConstraints, setInitConstraints] = useState(null)
    const [buildings, setBuildings] = useState([])
    const [holidays, setHolidays] = React.useState([])
    const [currentGroup, setCurrentGroup] = useState({
        name: null,
        objects: [],
    })
    const [controllers, setControllers] = useState([])
    const [observationEndDate, setObservationEndDate] = useState(null)
    const [modified, setModified] = useState(false)
    const [selectedTab, setSelectedTab] = useState(1)
    const [open, setOpen] = useState(false)
    const [ephemerous, setEphemerous] = useState(false)
    let location = useLocation()
    let { search } = useLocation()

    // const [scrollY, setScrollY] = useState(0);

    // useEffect(() => {
    //   console.log("scrollY", scrollY, window.scrollY)
    //   if (modal) {
    //     setScrollY(window.scrollY);
    //   } else if (selectedObject === null && scrollY > 0) {
    //     window.scrollTo(0, scrollY);
    //   }
    // }, [selectedObject, selectedSite]);

    useEffect(() => {
        const query = new URLSearchParams(search)
        const objectId = query.get('objectId')
        if (!objectId) {
            setModal(false)
            store.dispatch(GlobalSlice.actions.setSelectedObject(null))
        }
    }, [location])

    useEffect(() => {
        if (selectedSite) {
            setCurrentGroup({ name: null, objects: [] })
            setLoading(true)
            fetchObjects()
            fetchConstraints()
            fetchGroups()
        }
    }, [selectedSite, constraintsUpdated])

    useEffect(() => {
        setControllers([])
    }, [selectedSite])

    const fetchGroups = async () => {
        setGroups([])
        const res = await getGroups(selectedSite.Hash)
        if (res?.groups) {
            setGroups(res?.groups)
        }
    }

    const fetchObjects = async () => {
        setRawObjects([])

        const res = await getObjectsForSite({ hash: selectedSite.Hash })
        if (res && res.objects) {
            let objects = res.objects
            setRawObjects(objects)
            if (res?.site) {
                let newCheckIn =
                    res?.site?.CheckIn?.split(':')[0]?.length > 1 ? res?.site?.CheckIn : `0${res?.site?.CheckIn}`
                let newCheckOut =
                    res?.site?.CheckOut?.split(':')[0]?.length > 1 ? res?.site?.CheckOut : `0${res?.site?.CheckOut}`
                setCheckIn(newCheckIn)
                setCheckOut(newCheckOut)
                setDefaultCheckin(newCheckIn)
                setDefaultCheckout(newCheckOut)
                setObservationEndDate(res?.site?.ObservationEndDate)
                if (res?.site?.WeekendCheckIn) {
                    setWeekendCheckIn(res?.site?.WeekendCheckIn)
                }
                if (res?.site?.WeekendCheckOut) {
                    setWeekendCheckOut(res?.site?.WeekendCheckOut)
                }
            }
        }
        setLoading(false)
    }

    const fetchConstraints = async () => {
        const res = await getConstraints(selectedSite.Hash)
        if (res && res.constraints) {
            setConstraints({ ...res?.constraints })
            let newConstraints = { ...res?.constraints }

            for (let i = 0; i < Object.keys(res?.constraints).length; i++) {
                let key = Object.keys(res?.constraints)[i]
                let fingerPrint = hash({
                    events: res?.constraints[key].events || [],
                    pilotEvents: res?.constraints[key].pilotEvents || [],
                    constraintMin: res?.constraints[key].constraintMin,
                    constraintMax: res?.constraints[key].constraintMax,
                    inherited: res?.constraints[key].inherited,
                })

                newConstraints[key] = {
                    ...res?.constraints[key],
                    fingerPrint: fingerPrint,
                }
            }

            setInitConstraints(newConstraints)
        }
        if (res?.holidays) {
            let newHolidays = res?.holidays?.map((el) => {
                return { ...el, date: el.StartDate }
            })
            setHolidays(newHolidays)
        }
    }

    const findAllIds = async (children) => {
        let ids = []
        for (let i = 0; i < children?.length; i++) {
            let id = children[i]?.id
            let constraint = constraints[id]
            if (!constraint || !constraint?.inherited) {
                ids.push(id)
                if (children[i]?.children.length > 0) {
                    let newKeys = await findAllIds(children[i]?.children)
                    if (newKeys?.length > 0) ids = [...ids, ...newKeys]
                }
            }
        }
        return ids
    }

    const saveObject = async ({
        events,
        constraintMin,
        constraintMax,
        applyToAll,
        constraintMinHeat,
        constraintMaxHeat,
        pilot,
        noAgenda,
        pilotEvents,
    }) => {
        let newConstraints = { ...constraints }
        events = events.map((el) => {
            return {
                ...el,
                startDate: moment(el.startDate).format('YYYY-MM-DD'),
                startTime: el.startTime,
                endDate: moment(el.endDate).format('YYYY-MM-DD'),
                endTime: el.endTime,
            }
        })
        pilotEvents = pilotEvents.map((el) => {
            return {
                ...el,
                startDate: moment(el.startDate).format('YYYY-MM-DD'),
                startTime: el.startTime,
                endDate: moment(el.endDate).format('YYYY-MM-DD'),
                endTime: el.endTime,
            }
        })
        newConstraints[selectedObject.ObjectId] = {
            events,
            constraintMin: parseFloat(constraintMin),
            constraintMax: parseFloat(constraintMax),
            constraintMinHeat: parseFloat(constraintMinHeat),
            constraintMaxHeat: parseFloat(constraintMaxHeat),
            inherited: 1,
            pilot: pilot ? 1 : 0,
            noAgenda: noAgenda ? 1 : 0,
            pilotEvents: pilotEvents,
        }
        if (applyToAll) {
            let objectIds = []
            if (selectedObject?.ObjectTypeId === 1) {
                objectIds = rawObjects.map((el) => el.ObjectId)
            } else {
                let spaceType = selectedObject?.SpaceType
                objectIds = rawObjects.filter((el) => el.SpaceType == spaceType).map((el) => el.ObjectId)
            }

            for (let i = 0; i < objectIds?.length; i++) {
                newConstraints[objectIds[i]] = {
                    events,
                    pilotEvents,
                    pilot: pilot ? 1 : 0,
                    noAgenda: noAgenda ? 1 : 0,
                    constraintMin: parseFloat(constraintMin),
                    constraintMax: parseFloat(constraintMax),
                    constraintMinHeat: parseFloat(constraintMinHeat),
                    constraintMaxHeat: parseFloat(constraintMaxHeat),
                    inherited: 1,
                    previousEvents:
                        newConstraints[objectIds[i]]?.previousEvents || newConstraints[objectIds[i]]?.events,
                    previousConstraintMin:
                        newConstraints[objectIds[i]]?.previousConstraintMin ||
                        newConstraints[objectIds[i]]?.constraintMin,
                    previousConstraintMax:
                        newConstraints[objectIds[i]]?.previousConstraintMax ||
                        newConstraints[objectIds[i]]?.constraintMax,

                    previousConstraintMinHeat:
                        newConstraints[objectIds[i]]?.previousConstraintMinHeat ||
                        newConstraints[objectIds[i]]?.constraintMinHeat,
                    previousConstraintMaxHeat:
                        newConstraints[objectIds[i]]?.previousConstraintMaxHeat ||
                        newConstraints[objectIds[i]]?.constraintMaxHeat,
                }
            }
        }
        setConstraints(newConstraints)
        setConstraintsUpdated(true)
        // setModified(true)
    }

    const applyConstraintsToOther = (type) => {
        let newConstraints = { ...constraints }
        let object_constraints = newConstraints[selectedObject.ObjectId]
        let objectIds = []
        if (type === 'space_type') {
            if (selectedObject?.ObjectTypeId === 1) {
                objectIds = rawObjects.map((el) => el.ObjectId)
            } else {
                let spaceType = selectedObject?.SpaceType
                objectIds = rawObjects.filter((el) => el.SpaceType == spaceType).map((el) => el.ObjectId)
            }
        } else if (type) {
            objectIds = groups.find((el) => el.hash == type)?.objects
        }

        for (let i = 0; i < objectIds?.length; i++) {
            newConstraints[objectIds[i]] = {
                events: object_constraints.events,
                constraintMin: parseFloat(object_constraints?.constraintMin),
                constraintMax: parseFloat(object_constraints?.constraintMax),
                constraintMinHeat: parseFloat(object_constraints?.constraintMinHeat),
                constraintMaxHeat: parseFloat(object_constraints?.constraintMaxHeat),
                inherited: 1,
                pilot: object_constraints.pilot,
                noAgenda: object_constraints.noAgenda,
                previousEvents: newConstraints[objectIds[i]]?.previousEvents || newConstraints[objectIds[i]]?.events,
                previousConstraintMin:
                    newConstraints[objectIds[i]]?.previousConstraintMin || newConstraints[objectIds[i]]?.constraintMin,
                previousConstraintMax:
                    newConstraints[objectIds[i]]?.previousConstraintMax || newConstraints[objectIds[i]]?.constraintMax,
                previousConstraintMinHeat:
                    newConstraints[objectIds[i]]?.previousConstraintMinHeat ||
                    newConstraints[objectIds[i]]?.constraintMinHeat,
                previousConstraintMaxHeat:
                    newConstraints[objectIds[i]]?.previousConstraintMaxHeat ||
                    newConstraints[objectIds[i]]?.constraintMaxHeat,
            }
        }

        setConstraints(newConstraints)
        return newConstraints
    }

    const save = async (constraints) => {
        setLoading(true)
        // return;
        if (buildings?.length > 0) {
            await setupBuildings(selectedSite.Hash, { buildings })
        }

        let modifiedConstraints = {}
        for (let i = 0; i < Object.keys(constraints).length; i++) {
            let key = Object.keys(constraints)[i]
            let fingerPrint = hash({
                events: constraints[key].events || [],
                pilotEvents: constraints[key].pilotEvents || [],
                constraintMin: constraints[key].constraintMin,
                constraintMax: constraints[key].constraintMax,
                inherited: constraints[key].inherited,
            })
            if (fingerPrint !== initConstraints[key]?.fingerPrint) {
                modifiedConstraints[key] = {
                    ...constraints[key],
                    objectName: rawObjects.find((el) => el.ObjectId == key)?.Name,
                }
            }
        }
        console.log('modifiedConstraints', modifiedConstraints)
        if (constraintsUpdated) {
            let splittedConstraints = []
            for (let i = 0; i < Object.keys(modifiedConstraints).length; i++) {
                let key = Object.keys(modifiedConstraints)[i]
                //splittedConstraints[0][key] = modifiedConstraints[key];
                let index = Math.floor(i / 100)
                if (splittedConstraints.length <= index) {
                    splittedConstraints[index] = {}
                    splittedConstraints[index][key] = modifiedConstraints[key]
                } else splittedConstraints[index][key] = modifiedConstraints[key]
            }
            if (splittedConstraints.length > 0) {
                for (let i = 0; i < splittedConstraints.length; i++) {
                    if (i > 0) {
                        await postConstraints(
                            {
                                constraints: splittedConstraints[i],
                                holidays,
                            },
                            selectedSite.Hash
                        )
                    } else {
                        await postConstraints(
                            {
                                constraints: splittedConstraints[i],
                                objectUpdates,
                                holidays,
                            },
                            selectedSite.Hash
                        )
                    }
                }
            } else if (objectUpdates?.length > 0) {
                await postConstraints(
                    {
                        objectUpdates,
                        holidays,
                    },
                    selectedSite.Hash
                )
            }
        }

        if (selectedObject?.OldName) {
            let newObjects = rawObjects?.map((el) => {
                if (el?.ObjectId === selectedObject?.ObjectId) return { ...el, Name: selectedObject?.Name }
                else return el
            })
            setRawObjects(newObjects)
        }

        if (selectedObject?.OldName || modified) {
            await postConstraints(
                {
                    newObject: {
                        ObjectId: selectedObject?.ObjectId,
                        NewName: selectedObject?.Name,
                    },
                    holidays,
                },
                selectedSite.Hash
            )
        }

        if ((checkIn || checkOut) && selectedSite?.SiteType === 'Hotel') {
            await applyCheckin()
        }
        // await sleep(1000);
        await fetchObjects()
        setOpen(true)
        setLoading(false)
        event('Constraints', 'Change constraints')
        setModified(false)
    }

    const applyReco = async () => {
        //let find = rawObjects.find((el) => el.ObjectTypeId === 1);
        //if (find) store.dispatch(GlobalSlice.actions.setSelectedObject(find));
        let newConstraints = constraints
        for (let i = 0; i < rawObjects?.length; i++) {
            let id = rawObjects[i].ObjectId
            newConstraints[id] = {
                ...constraints[id],
                constraintMax: 26,
                constraintMin: 19,
            }
        }
        setConstraints(newConstraints)
        setConstraintsUpdated(true)
        // await sleep(1000);
        await save(constraints)
        // setShowSnackBar(true);
    }

    const changeSpaceType = async (objectId, objectType) => {
        await postConstraints(
            {
                objectUpdates: [{ objectId: objectId, objectType: objectType }],
                holidays,
            },
            selectedSite.Hash
        )
    }

    const applyCheckin = async () => {
        if (checkIn != defaultCheckin || checkOut != defaultCheckout) {
            await postCheckin({ checkIn, checkOut }, selectedSite?.Hash)
        }
    }

    const applyWeekendCheckin = async () => {
        if (weekendCheckIn || weekendCheckOut) {
            await postCheckin({ weekendCheckIn, weekendCheckOut, checkIn, checkOut }, selectedSite?.Hash)
        }
    }

    const autoSave = async ({ _holidays, _checkin, _checkout, _buildings, _weekendCheckIn, _weekendCheckOut }) => {
        /** we want to check if something has changed regarding holidays or space types */
        if (_holidays) {
            setHolidays(_holidays)
            await postConstraints(
                {
                    holidays: _holidays?.map((el) => {
                        return { ...el, type: el.type || el?.Type }
                    }),
                },
                selectedSite.Hash
            )
        }
        if (_checkin) {
            setCheckIn(_checkin)
            await postCheckin({ checkIn: _checkin, checkOut, weekendCheckIn, weekendCheckOut }, selectedSite?.Hash)
        }
        if (_checkout) {
            setCheckOut(_checkout)
            await postCheckin({ checkIn, checkOut: _checkout, weekendCheckIn, weekendCheckOut }, selectedSite?.Hash)
        }
        if (_buildings) {
            setBuildings(_buildings)
            await setupBuildings(selectedSite.Hash, { buildings: _buildings })
        }
        if (_weekendCheckIn == null && _weekendCheckOut == null && !(_checkin || _checkout)) {
            setWeekendCheckIn(null)
            setWeekendCheckOut(null)
            await postCheckin({ weekendCheckIn: null, weekendCheckOut: null, checkIn, checkOut }, selectedSite?.Hash)
        } else {
            if (_weekendCheckIn) {
                setWeekendCheckIn(_weekendCheckIn)
                await postCheckin(
                    { weekendCheckIn: _weekendCheckIn, weekendCheckOut, checkIn, checkOut },
                    selectedSite?.Hash
                )
            }
            if (_weekendCheckOut) {
                setWeekendCheckOut(_weekendCheckOut)
                await postCheckin(
                    { weekendCheckIn, weekendCheckOut: _weekendCheckOut, checkIn, checkOut },
                    selectedSite?.Hash
                )
            }
        }
    }

    useEffect(() => {
        if (selectedObject?.ObjectId) {
            setModal(true)
            navigate(`/constraints?site=${selectedSite?.Hash}&objectId=${selectedObject.ObjectId}`)
        }
    }, [selectedObject])

    const updateCurrentGroup = (objectId) => {
        let index = currentGroup?.objects?.indexOf(objectId)
        if (index === -1) {
            setCurrentGroup({
                ...currentGroup,
                objects: [...currentGroup.objects, objectId],
            })
        } else {
            setCurrentGroup({
                ...currentGroup,
                objects: currentGroup?.objects?.filter((item) => item !== objectId),
            })
        }
    }

    const updateGroup = async () => {
        setGroupDialog(false)
        if (!currentGroup.name) return
        setLoading(true)

        let find = groups.find((el) => el?.name === currentGroup.name)
        let newGroups = groups
        if (!find) {
            newGroups = groups
            newGroups.push(currentGroup)
        } else {
            newGroups = newGroups.map((el) => {
                if (el?.name === currentGroup.name) return currentGroup
                else return el
            })
        }

        await postGroups({ groups: newGroups }, selectedSite.Hash)
        setGroups(newGroups)

        // setCurrentGroup({
        //   objects: [],
        //   name: null,
        // });
        setLoading(false)
    }

    const removeGroup = async () => {
        setLoading(true)
        let newGroups = groups.filter((el) => el.name !== currentGroup?.name)
        setGroups(newGroups)

        await postGroups({ groups: newGroups }, selectedSite.Hash)
        setCurrentGroup({
            objects: [],
            name: null,
        })
        setLoading(false)
    }

    const button = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'row', sm: 'row', md: 'row', lg: 'row' },
                }}
            >
                {/* {selectedObject ? (
                    <ForceResetComponent
                        selectedSite={selectedSite}
                        selectedObject={selectedObject}
                        constraints={initConstraints}
                    />
                ) : null} */}
                <SaveMenu
                    title="Enregistrer"
                    saveModifications={async (event) => {
                        if (event === 'zone') {
                            await save(constraints)
                        } else if (event === 'ephemerous') {
                            setEphemerous(true)
                            // let object_id = selectedObject?.ObjectId;
                            // let newConstraints = constraints;
                            // newConstraints[object_id] = { ...newConstraints[object_id], ephemerous: true, old_constraint: initConstraints[object_id] };

                            // await save(newConstraints);
                        } else {
                            let out = await applyConstraintsToOther(event)
                            await save(out)
                        }
                    }}
                    items={groups.filter((el) => el?.objects?.indexOf(selectedObject?.ObjectId) > -1)}
                />
            </Box>
        )
    }
    const headerPortalContainer = document.getElementById('header-right-content-portal')

    return (
        <>
            {headerPortalContainer &&
                createPortal(
                    !modal ? null : modified ? (
                        <Badge color="secondary" variant="dot">
                            {button()}
                        </Badge>
                    ) : (
                        button()
                    ),
                    headerPortalContainer
                )}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setOpen(false)}
            >
                <Alert severity="success" sx={{ width: '100%' }} on>
                    Modifications enregistrées
                </Alert>
            </Snackbar>
            {ephemerous && (
                <EphermerousDialog
                    ephemerous={ephemerous}
                    setEphemerous={setEphemerous}
                    submit={async (days) => {
                        setEphemerous(false)
                        console.log('')
                        let object_id = selectedObject?.ObjectId
                        let newConstraints = constraints
                        newConstraints[object_id] = {
                            ...newConstraints[object_id],
                            ephemerous: true,
                            old_constraint: initConstraints[object_id],
                            days: days,
                        }

                        if (selectedObject.Pilot !== newConstraints[object_id]?.pilot) {
                            newConstraints[object_id]['old_constraint'] = {
                                ...newConstraints[object_id]['old_constraint'],
                                old_pilot: selectedObject.Pilot,
                            }
                        }

                        console.log('selectedObject', selectedObject)

                        await save(newConstraints)
                    }}
                />
            )}

            {/* <ContainerComponent style={{ position: "fixed", width: "calc(100% - 240px)", boxSizing: "border-box", left: 240}}> */}
            <ContainerComponent style={{ maxWidth: globalStore.width }}>
                <DialogComponent
                    dialog={dialog}
                    setDialog={setDialog}
                    title={'Confirmation'}
                    description={locale?.['navBar']?.['confirmation_1']}
                    noText={locale?.['no']}
                    yesText={locale?.['yes']}
                    accept={async () => {
                        event('ApplyRecoConstraints', `${selectedSite?.Name}`)
                        await applyReco()
                        setDialog(false)
                    }}
                />

                <DialogComponent
                    dialog={groupDialog}
                    setDialog={setGroupDialog}
                    title={locale?.['constraints']?.['newGroup']}
                    reset={() => setCurrentGroup({ name: null, objects: [] })}
                    description={
                        <>
                            <DialogContentText id="group-dialog-description">
                                {locale?.['constraints']?.['groupNameQuestion']}
                            </DialogContentText>
                            <TextField
                                style={{ marginTop: 10 }}
                                value={currentGroup.name}
                                placeholder={locale?.['constraints']?.['groupNamePlaceholder']}
                                onChange={(e) => setCurrentGroup({ ...currentGroup, name: e.target.value })}
                            />
                        </>
                    }
                    yesText={locale?.['constraints']?.['confirmation']}
                    accept={updateGroup}
                />
                {!selectedObject && (
                    <Grid container style={{ maxWidth: '100%', overflow: 'scroll' }}>
                        <Grid item md={12}>
                            <Tabs
                                value={selectedTab}
                                onChange={(event, value) => {
                                    setSelectedTab(value)
                                }}
                                style={{
                                    overflow: 'scroll',
                                    width: '100%',
                                }}
                                aria-label="basic tabs example"
                            >
                                {me?.userPermissions?.find(
                                    (permission) =>
                                        permission?.Hash === selectedSite?.Hash &&
                                        permission?.Section === 'ConstraintsView' &&
                                        ['Manage']?.includes(permission?.GrantType)
                                ) || isDemo() ? (
                                    <Tab label="Configuration du site" value={0} />
                                ) : null}

                                <Tab label="Configuration des zones" value={1} />
                                {controllers?.length === 1 ||
                                !me?.userPermissions?.find(
                                    (permission) =>
                                        permission?.Hash === selectedSite?.Hash &&
                                        permission?.Section === 'ConstraintsView' &&
                                        ['Manage']?.includes(permission?.GrantType)
                                ) ? null : (
                                    <Tab label="Configuration des bâtiments" value={2} />
                                )}
                            </Tabs>
                        </Grid>
                    </Grid>
                )}

                {selectedTab === 0 && (
                    <Tab1Component
                        holidays={holidays}
                        autoSave={autoSave}
                        checkOut={checkOut}
                        checkIn={checkIn}
                        observationEndDate={observationEndDate}
                        weekendCheckIn={weekendCheckIn}
                        weekendCheckOut={weekendCheckOut}
                    />
                )}
                {selectedTab === 1 && (
                    <Tab2Component
                        modal={modal}
                        setModal={setModal}
                        saveObject={saveObject}
                        constraints={constraints}
                        setModified={setModified}
                        rawObjects={rawObjects}
                        selectedObject={selectedObject}
                        setObjectUpdates={setObjectUpdates}
                        changeSpaceType={changeSpaceType}
                        setRawObjects={setRawObjects}
                        currentGroup={currentGroup}
                        updateCurrentGroup={updateCurrentGroup}
                        objectUpdates={objectUpdates}
                        groups={groups}
                        removeGroup={removeGroup}
                        setGroupDialog={setGroupDialog}
                        updateGroup={updateGroup}
                        groupDialog={groupDialog}
                        setCurrentGroup={setCurrentGroup}
                    />
                )}
                <Tab3Component
                    updateBuildings={(e) => autoSave({ _buildings: e })}
                    display={selectedTab == 2}
                    _setControllers={setControllers}
                />

                {!loading && !(rawObjects?.length > 0) && <SetupComponent />}
                {loading && (
                    <Backdrop open={true} style={{ color: '#fff', zIndex: 20000000 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </ContainerComponent>
        </>
    )
}

export const EphermerousDialog = ({ ephemerous, setEphemerous, submit }) => {
    const [days, setDays] = useState(1)

    const handleClose = () => {
        setEphemerous(false)
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Enregistrement éphémère</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Veuillez choisir le nombre de jours pour lesquels vous souhaitez appliquer les contraintes
                    </DialogContentText>
                    <div
                        style={{
                            width: '100%',
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: 20,
                        }}
                    >
                        <Select
                            autoFocus
                            onChange={(e) => setDays(e.target.value)}
                            width={'100%'}
                            style={{ width: '70%' }}
                            value={days}
                            placeholder="Nombre de jours"
                        >
                            {[...Array(15).keys()]
                                .map((e) => e + 1)
                                .map((e) => (
                                    <MenuItem value={e}>{e}</MenuItem>
                                ))}
                        </Select>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={() => submit(days)}>Enregistrer</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ConstraintsPage
