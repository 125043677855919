import React, { useEffect, useState } from 'react'
import { CardBody, CardHeader } from 'reactstrap'
import QRcode from '../../assets/img/other/qrcode.png'
import { Alert, Backdrop, Box, Card, CircularProgress, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { TitleComponent } from '../../components/Basic/library'
import { getObjectsForSite } from '../../services/realtime.service'
import { deleteNote, getSiteNotes, getSiteSettings } from '../../services/site.service'
import { sleep } from '../../utils/global.utils'
import useType from '../../utils/hooks.utils'
import useLocale from '../../utils/locale/locale.hook'
import DocumentModal from './site/document.modal'
import BasicTable from './site/documents.table'
import NoteTable from './site/notes.table'
import { ContainerComponent } from '../../components/Global/container.component'
import { isDemo } from '../../services/utils.service'
import { NoteComponent } from './site/notes.component'

const DocumentsPage = () => {
    const me = useSelector((state) => state.meStore?.me)

    const [loading, setLoading] = useState(false)
    const locale = useLocale()
    const [objects, setObjects] = useState([])
    const { selectedSite } = useSelector((state) => state.globalStore)
    const [parsedObjects, setParsedObjects] = useState([])
    const [documents, setDocuments] = useState([])
    const [notes, setNotes] = useState(null)
    const [selectedDocument, setSelectedDocument] = useState(null)
    const [selectedNote, setSelectedNote] = useState(null)
    const [documentLoader, setDocumentLoader] = useState(false)
    const documentTypes = ['doe', 'plan', 'edf', 'enedis', 'report', 'invoice', 'onboarding', 'contract']
    const { width } = useSelector((state) => state.globalStore)
    const [backdrop, setBackdrop] = useState(false)

    useEffect(() => {
        if (selectedSite?.Hash) {
            fetchObjects()
            init()
        }
    }, [selectedSite])

    const fetchObjects = async () => {
        setParsedObjects([])
        const res = await getObjectsForSite({ hash: selectedSite?.Hash })

        if (res.objects) {
            let newObjects = isDemo()
                ? res?.objects
                : res?.objects?.filter((el) => el?.ObjectTypeId !== 1 && el?.ObjectTypeId !== 3 && el?.ObjectHash)
            setObjects([...newObjects])
            await sleep(1000)
            let isOk = await verify([...newObjects])
            while (!isOk) {
                await sleep(1000)
                isOk = await verify([...newObjects])
            }
            let items = newObjects.map((el) => {
                return {
                    qr: document?.getElementById(`qrcode_${el?.ObjectHash}`)?.toDataURL(),
                    name: el?.Name,
                }
            })
            setParsedObjects(items)
        }
    }

    const init = async () => {
        setDocumentLoader(true)
        let res = await getSiteSettings(selectedSite?.Hash)
        if (res?.documents) setDocuments(res?.documents)
        res = await getSiteNotes(selectedSite?.Hash)
        if (res?.notes) setNotes(res?.notes)
        setDocumentLoader(false)
    }

    const verify = async (obj) => {
        if (obj?.length === 0) return false
        let out = true
        for (let i = 0; i < obj.length; i++) {
            let item = document?.getElementById(`qrcode_${obj[i]?.ObjectHash}`)?.toDataURL()

            if (!item || item?.length < 1000) {
                return false
            }
        }
        return out
    }

    return (
        <>
            {selectedDocument && (
                <DocumentModal
                    open={selectedDocument ? true : false}
                    handleClose={() => {
                        setSelectedDocument(null)
                        init()
                    }}
                    selectedDocument={selectedDocument}
                    documents={documents}
                    selectedSite={selectedSite}
                    documentTypes={documentTypes}
                />
            )}

            {backdrop && (
                <Backdrop open={true} style={{ zIndex: 10, color: 'white' }}>
                    {/* <CircularProgress color="inherit" /> */}
                    {locale?.pages?.['settings']?.['loadingPdf']}
                </Backdrop>
            )}
            <ContainerComponent>
                <Grid container spacing={2}>
                    {selectedSite?.SiteType == 'Hotel' && (
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <TitleComponent title={'Etiquettes'} />

                                    <a
                                        href={`/pdf-small?hash=${selectedSite?.Hash}`}
                                        target={'_blank'}
                                        style={{ color: '#03a9f4' }}
                                        rel="noreferrer"
                                    >
                                        Voir les étiquettes
                                    </a>
                                </CardHeader>
                                <CardBody>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: { xs: 'column', md: 'row' },
                                        }}
                                    >
                                        <img src={QRcode} />
                                        <div>
                                            <p>
                                                Nous proposons des étiquettes à imprimer et mettre dans les chambres de
                                                votre hôtel. Ces étiquettes contiennent un QR code qui permettra aux
                                                clients de scanner l'étiquette et de mieux comprendre l'engagement de
                                                votre hôtel pour une planète plus propre.
                                            </p>
                                            <Alert severity="warning">
                                                Les noms des chambres sont affichés de telle sorte que l'impression doit
                                                se faire recto-verso pour avoir la bonne correspondance.
                                            </Alert>
                                        </div>
                                    </Box>
                                </CardBody>
                            </Card>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                width: { sm: width - 32, xs: width - 32, md: '100%' },
                                overflow: 'scroll',
                            }}
                        >
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <TitleComponent title={'Mes documents'} />

                                    {me?.userPermissions?.find(
                                        (permission) =>
                                            permission?.Hash === selectedSite?.Hash &&
                                            permission?.Section === 'DocumentsView' &&
                                            ['Edit', 'Manage']?.includes(permission?.GrantType)
                                    ) &&
                                        !isDemo() && (
                                            <div
                                                style={{ color: '#03a9f4', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setSelectedDocument({
                                                        DocumentType: documentTypes?.[0],
                                                        Name: '',
                                                    })
                                                }}
                                            >
                                                Ajouter
                                            </div>
                                        )}
                                </CardHeader>
                                <CardBody>
                                    <BasicTable
                                        documents={documents?.filter(
                                            (el) => documentTypes?.indexOf(el.DocumentType) > -1
                                        )}
                                        setSelectedDocument={setSelectedDocument}
                                        editable={me?.userPermissions?.find(
                                            (permission) =>
                                                permission?.Hash === selectedSite?.Hash &&
                                                permission?.Section === 'DocumentsView' &&
                                                ['Edit', 'Manage']?.includes(permission?.GrantType)
                                        )}
                                        documentLoader={documentLoader}
                                    />
                                </CardBody>
                            </Card>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <NoteComponent
                            notes={notes}
                            addNote={(newNote) => {
                                let exists = notes?.find((el) => el?.MaintenanceNoteId === newNote?.MaintenanceNoteId)
                                if (exists) {
                                    let newNotes = notes?.map((el) => {
                                        if (el?.MaintenanceNoteId === newNote?.MaintenanceNoteId) {
                                            return newNote
                                        }
                                        return el
                                    })
                                    setNotes([...newNotes])
                                    return
                                }
                                setNotes([...notes, newNote])
                            }}
                            removeNote={async (note) => {
                                let newNotes = notes?.filter((el) => el?.MaintenanceNoteId !== note?.MaintenanceNoteId)
                                setNotes([...newNotes])
                                await deleteNote(selectedSite?.Hash, note?.MaintenanceNoteId)
                            }}
                        />
                    </Grid>
                    {loading && (
                        <Backdrop open={true} style={{ color: '#fff', zIndex: 1000 }}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )}
                </Grid>
            </ContainerComponent>
        </>
    )
}

export default DocumentsPage
