import { RouteData, RoutePaths } from './route'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import ArchitectureIcon from '@mui/icons-material/Architecture'
import DashboardIcon from '@mui/icons-material/Dashboard'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import LiveTvIcon from '@mui/icons-material/LiveTv'
import LockIcon from '@mui/icons-material/Lock'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { User } from '@/types/user'
import { isDemo } from '@/services/utils.service'

export const mainRoutesList: RouteData[] = [
    {
        title: 'Dashboard',
        navbarTitle: 'Vue globale',
        titleLink: true,
        path: RoutePaths.Dashboard,
        admin: true,
        enabled: ['admin'],
        access: ['Editor', 'Viewer'],
        accessKey: ['DashboardGroupView', 'DashboardSiteView'],
        Icon: DashboardIcon,
    },
    {
        title: 'Pilotage',
        navbarTitle: 'Pilotage',
        path: RoutePaths.Realtime,
        enabled: ['user', 'admin'],
        access: ['Editor'],
        accessKey: ['RealtimeView'],
        Icon: LiveTvIcon,
    },
    {
        title: 'Configuration',
        navbarTitle: 'Configuration',
        path: RoutePaths.Constraints,
        admin: true,
        enabled: ['admin'],
        access: ['Editor'],
        accessKey: ['ConstraintsView'],
        Icon: ArchitectureIcon,
    },
    {
        title: 'Mes documents',
        navbarTitle: 'Documents',
        minScope: 'site',
        path: RoutePaths.Documents,
        enabled: ['admin', 'worker'],
        access: ['Editor', 'Viewer'],
        accessKey: ['DocumentsView'],
        Icon: LibraryBooksIcon,
    },
    {
        title: 'Aide',
        navbarTitle: "Aide d'Agrid",
        minScope: 'root',
        path: RoutePaths.Help,
        enabled: ['admin', 'worker', 'user'],
        access: ['Editor', 'Viewer'],
        Icon: HelpOutlineIcon,
    },
    {
        title: 'Contact',
        navbarTitle: 'Contactez-nous',
        minScope: 'root',
        path: RoutePaths.Contact,
        enabled: ['admin', 'worker', 'user'],
        access: ['Editor', 'Viewer'],
        Icon: SupportAgentIcon,
    },
    // {
    //     title: 'Support',
    //     navbarTitle: 'Support',
    //     minScope: 'root',
    //     path: RoutePaths.Support,
    //     enabled: ['admin', 'worker', 'user'],
    //     access: ['Editor', 'Viewer'],
    //     whitelist: [
    //         'admin@a-grid.com',
    //         'charles.moreau@a-grid.com',
    //         'valentin.malinge@a-grid.com',
    //         'ylan.brami@a-grid.com',
    //         'lucas.david@a-grid.com',
    //     ],
    //     Icon: SupportAgentIcon,
    // },
    {
        title: 'Mon espace',
        navbarTitle: 'Réglages',
        minScope: 'root',
        path: RoutePaths.Settings,
        enabled: ['admin', 'worker', 'user'],
        access: ['Editor', 'Viewer'],
        Icon: LockIcon,
    },
]

export const isUserAllowed = (route: RouteData, user?: User) => {
    if (isDemo() || !route.accessKey) {
        return true
    }
    if (!user) {
        return false
    }
    const hasPermission =
        Boolean(user.userPermissions.find((permission) => route.accessKey?.includes(permission.section))) ||
        route.whitelist?.includes(user.email)
    return hasPermission
}
