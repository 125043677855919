import DownloadIcon from '@mui/icons-material/Download'
import {
    Backdrop,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { linkBlue } from '../../../components/Basic/colors'
import { DescriptionComponent, TitleComponent } from '../../../components/Basic/library'
import Layout from '../../../components/Global/layout.component'
import NoDataComponent from '../../../components/Global/no-data.component'
import { getSiteReports } from '../../../services/site.service'
import { getMe, updateUser } from '../../../services/user.service'
import { store } from '../../../store/configure.store'
import MeSlice from '../../../store/slices/me.slice'
import useLocale from '../../../utils/locale/locale.hook'

export const SiteDetails = () => {
    const me = useSelector((state) => state.meStore?.me)
    const navigate = useNavigate()
    const location = useLocation()
    const [site, setSite] = useState({})
    const [reports, setReports] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [loading, setLoading] = useState(false)
    const locale = useLocale()
    useEffect(() => {
        setSite(location.state.site)
    }, [location])

    useEffect(() => {
        if (site?.Hash) {
            init()
        }
    }, [site])

    const update = async ({ sendReport, hash, notification_1 }) => {
        setLoading(true)
        await updateUser({ hash, sendReport, notification_1 })
        let me = await getMe()
        store.dispatch(MeSlice.actions.setMe({ ...me }))
        setLoading(false)
    }

    const init = async () => {
        setLoaded(false)
        let res = await getSiteReports(site?.Hash)
        if (res?.reports) {
            setReports(res?.reports)
        }
        setLoaded(true)
    }
    return (
        <Layout title={`${locale?.['admin']?.['details']} - ${site?.Name}`}>
            <Button
                onClick={() => {
                    navigate(`/settings/admin`)
                }}
            >
                {locale?.['admin']?.['back']}
            </Button>{' '}
            {loading && (
                <Backdrop open={true} style={{ zIndex: 1000, color: 'white' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card style={{ marginTop: 20 }}>
                        <CardContent>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <TitleComponent title={locale?.['admin']?.['notifications']} />
                            </div>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <DescriptionComponent content={locale?.['admin']?.['notif_1']} />
                                            </TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={
                                                        me?.sites?.find((el) => el?.Hash === site?.Hash)?.Notification_1
                                                    }
                                                    onChange={() => {
                                                        update({
                                                            hash: site?.Hash,
                                                            notification_1:
                                                                1 -
                                                                me?.sites?.find((el) => el?.Hash === site?.Hash)
                                                                    ?.Notification_1,
                                                        })
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <DescriptionComponent content={locale?.['admin']?.['sendReport']} />
                                            </TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={
                                                        me?.sites?.find((el) => el?.Hash === site?.Hash)?.SendReport
                                                    }
                                                    onChange={() => {
                                                        update({
                                                            hash: site?.Hash,
                                                            sendReport:
                                                                1 -
                                                                me?.sites?.find((el) => el?.Hash === site?.Hash)
                                                                    ?.SendReport,
                                                        })
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card style={{ marginTop: 20 }}>
                        <CardContent>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <TitleComponent title={locale?.['admin']?.['reports']} />
                            </div>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {!loaded ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                {' '}
                                                <CircularProgress size={'20px'} />
                                            </div>
                                        ) : (
                                            reports?.map((el) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>
                                                            <DescriptionComponent
                                                                content={moment(el?.CreatedAt).format('DD-MM-YYYY')}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <a href={el?.Path} target="_blank" rel="noreferrer">
                                                                <DownloadIcon
                                                                    style={{ cursor: 'pointer', color: linkBlue }}
                                                                />
                                                            </a>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        )}
                                        {loaded && reports?.length === 0 && (
                                            <div style={{ marginTop: 20 }}>
                                                <NoDataComponent navigate={navigate} />
                                            </div>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Layout>
    )
}
