import {
    Box,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    Alert,
} from '@mui/material'
import { TitleComponent } from '../../../components/Basic/library'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import Calendar from 'react-calendar'
// import '../../../assets/css/calendar.css'
import { CardHeader } from 'reactstrap'
import DeleteIcon from '@mui/icons-material/Delete'
import { postNote } from '@/services/site.service'
import { EnhancedNote } from './enhanced-note.component'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'

export const NoteComponent = ({ notes, addNote, removeNote }) => {
    const { width } = useSelector((state) => state.globalStore)
    const [selectedNote, setSelectedNote] = useState(null)
    const [open, setOpen] = useState(false)
    const [calendarOpen, setCalendarOpen] = useState(false) // State to toggle calendar visibility
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false) // State to manage delete confirmation dialog
    const [noteToDelete, setNoteToDelete] = useState(null) // State to store the note to be deleted
    const [newNote, setNewNote] = useState({
        Title: '',
        Note: '',
        Date: new Date(),
        MaintenanceNoteId: Math.random(),
    })
    const { selectedSite } = useSelector((state) => state.globalStore)
    const handleOpen = () => {
        setOpen(true)
        setNewNote({
            Title: '',
            Note: '',
            Date: new Date(),
            MaintenanceNoteId: Math.random(),
        })
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleTitleChange = (e) => {
        setNewNote({
            ...newNote,
            Title: e.target.value,
        })
    }

    const handleNoteChange = (content) => {
        setNewNote((prevNote) => ({
            ...prevNote,
            Note: content,
        }))
    }

    const handleDateChange = (date) => {
        setNewNote({
            ...newNote,
            Date: date,
            MaintenanceNoteId: newNote?.MaintenanceNoteId || Math.random(),
        })
        setCalendarOpen(false) // Close calendar after selecting a date
    }

    const handleSubmit = async () => {
        if (newNote.Title && newNote.Note) {
            addNote(newNote) // Call the addNote function passed via props
            handleClose() // Close the dialog
            let date = newNote.Date
            if (typeof date === 'string') {
                date = new Date(date)
            }
            // set hour to 12:00
            date.setHours(12, 0, 0, 0)
            await postNote(selectedSite?.Hash, {
                note: { ...newNote, Date: date },
            })
            setNewNote({ Title: '', Note: '', Date: new Date() }) // Clear the form
        }
    }

    const openDeleteDialog = (note) => {
        setNoteToDelete(note) // Set the note to be deleted
        setDeleteDialogOpen(true) // Open the delete confirmation dialog
    }

    const handleDelete = () => {
        removeNote(noteToDelete) // Remove the selected note
        setDeleteDialogOpen(false) // Close the delete confirmation dialog
    }

    const handleCancelDelete = () => {
        setDeleteDialogOpen(false) // Close the delete confirmation dialog without deleting
        setNoteToDelete(null) // Reset the note to delete
    }

    return (
        <Box
            sx={{
                width: { sm: width - 32, xs: width - 32, md: '100%' },
                overflow: 'scroll',
            }}
        >
            <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TitleComponent title={'Mes notes'} />
                    <div style={{ color: '#03a9f4', cursor: 'pointer' }} onClick={handleOpen}>
                        Ajouter
                    </div>
                </CardHeader>
                <CardContent>
                    {notes === null ? null : notes?.length === 0 ? (
                        <Alert severity="info">Aucune note n'a été ajoutée</Alert>
                    ) : (
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={200}>Date</TableCell>
                                        <TableCell>Titre</TableCell>
                                        <TableCell>Détails</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {notes
                                        ?.sort((a, b) => {
                                            return a?.Date < b?.Date ? -1 : 1
                                        })
                                        ?.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.Date ? moment(row.Date).format('DD-MM-YYYY') : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    <div
                                                        style={{ color: '#03a9f4', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setNewNote(row)
                                                            setOpen(true)
                                                        }}
                                                    >
                                                        {row.Title}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div dangerouslySetInnerHTML={{ __html: row.Note }} />
                                                </TableCell>
                                                {/* <TableCell component="th" scope="row">
                                                {row.Note?.substring(0, 50)}...
                                            </TableCell> */}
                                                <TableCell>
                                                    <DeleteIcon
                                                        style={{ color: 'red', cursor: 'pointer' }}
                                                        onClick={() => openDeleteDialog(row)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </CardContent>
            </Card>

            {/* Dialog for adding a new note */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
                    Ajouter une nouvelle note{' '}
                    {/* <div
                        style={{ cursor: 'pointer', color: '#03a9f4', fontSize: 14 }}
                        onClick={() => setCalendarOpen(!calendarOpen)}
                    >
                       
                         {newNote.CreatedAt ? moment(newNote.CreatedAt).format('DD-MM-YYYY') : 'Sélectionner une date'} 
                    </div> */}
                    <div style={{ fontSize: 12 }}>
                        <DatePicker
                            onChange={(e) => {
                                handleDateChange(e)
                            }}
                            value={newNote.Date}
                        />
                    </div>
                </DialogTitle>{' '}
                <DialogContent>
                    {calendarOpen ? null : (
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Titre"
                                name="Title"
                                value={newNote.Title}
                                onChange={handleTitleChange}
                                fullWidth
                                variant="outlined"
                            />
                            <EnhancedNote
                                content={newNote.Note}
                                onContentChange={(content) => handleNoteChange(content)}
                            />
                            {/* <TextField
                                margin="dense"
                                label="Note"
                                name="Note"
                                value={newNote.Note}
                                onChange={handleChange}
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                            /> */}
                        </>
                    )}

                    {/* Display selected date and allow the calendar to be triggered */}

                    {/* Show the calendar only when triggered */}
                    {/* <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                        {calendarOpen && (
                            <Calendar
                                className="agrid-calendar"
                                onChange={handleDateChange}
                                value={newNote.CreatedAt}
                                maxDate={new Date()}
                            />
                        )}
                    </div> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        {newNote?.MaintenanceNoteId > 1 ? 'Enregistrer' : 'Ajouter'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
                <DialogTitle>Confirmer la suppression</DialogTitle>
                <DialogContent>
                    <DialogContentText>Êtes-vous sûr de vouloir supprimer cette note ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
